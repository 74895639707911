:root {

    /*
    ####################
    # UNIVERSAL COLORS #
    ####################
    */

    --clr-white:  #ffffff;
    --clr-gray:   #aaaaaa;

    --clr-1: rgb(50,75,150);
    --clr-2: rgb(50,150,255);
    --clr-3: white;

    /*
    ####################
    # LIGHTMODE COLORS #
    ####################
    */

    --clr-gray-1: white;
    --clr-gray-2: #fafafa;
    --clr-gray-3: #f5f5f5;
    --clr-gray-4: #d2d2d2;
    --clr-gray-5: #bcbcbc;
    --clr-gray-6: rgba(0,0,0,0.05);
    --clr-gray-7: black;

    /*
    ####################
    */

    --clr-component:   var(--clr-gray-1);
    --clr-container:   var(--clr-gray-2);
    --clr-fill-light:  var(--clr-gray-3);
    --clr-fill-dark:   var(--clr-gray-5);

    --clr-border:      var(--clr-gray-5);
    --clr-shadow:      var(--clr-gray-6);
    --clr-font:        var(--clr-gray-7);
    --clr-nav:         var(--clr-1);
}

.dark-mode {

    /*
    ####################
    # DARKMODE COLORS  #
    ####################
    */
  
    --clr-1: var(--clr-black);
    --clr-2: #2a2a2a;
    --clr-3: #3e3e3e;
    --clr-4: #535353;
    --clr-5: #676767;
    --clr-6: rgba(255,255,255,0.05);
    --clr-7: var(--clr-white);

    /*
    ####################
    */

    --clr-component:   var(--clr-2);
    --clr-container:   var(--clr-1);
    --clr-fill-light:  var(--clr-3);
    --clr-fill-dark:   var(--clr-5);

    --clr-border:      var(--clr-5);
    --clr-shadow:      var(--clr-6);
    --clr-font:        var(--clr-7);
    --clr-placeholder: var(--clr-gray);
    --clr-nav:         rgb(32,32,32);
}