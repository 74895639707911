:root {

  /*
  ####################
  # SIZE DEFINITIONS #
  ####################
  */

  --rds-s:      4px;
  --rds-l:      8px;

  --spc-xxs:    2px;
  --spc-xs:     4px;
  --spc-s:      8px;
  --spc-m:     16px;
  --spc-l:     24px;
  --spc-xl:    32px;
  --spc-xxl:   48px;

  --txt-xxs:   0.25rem;
  --txt-xs:    0.50rem;
  --txt-s:     0.75rem;
  --txt-m:     1.00rem;
  --txt-l:     1.25rem;
  --txt-xl:    1.50rem;
  --txt-xxl:   2.00rem;

  /*
  ####################
  */

  --siz-img-s: 40px;
  --siz-img-l: 80px;
  
  --rds-control: var(--rds-s);
  --rds-component: var(--rds-l);

  --siz-control-s: var(--spc-l);
  --siz-control-m: var(--spc-xl);
  --siz-control-l: var(--spc-xxl);

  --pdg-control-s: var(--spc-xs);
  --pdg-control-m: var(--spc-s);
  --pdg-control-l: var(--spc-m);
  --pdg-component: var(--spc-l);
  --pdg-container: 5vw;

  --sdw-control: TODO;
  --sdw-component: 0px 0px 40px 0px var(--clr-shadow);

  --lyr-alert: 99;
  --lyr-menu: 98;
  --lyr-form: 97;
  --lyr-nav: 96;
}

@media (max-width: 480px) {
  :root {
    --siz-control-m: var(--spc-l);
    --siz-control-l: var(--spc-xl);

    --pdg-control-m: var(--spc-xs);
    --pdg-control-l: var(--spc-s);
    --pdg-component: var(--spc-m);

    --txt-xxs:   0.25rem;
    --txt-xs:    0.50rem;
    --txt-s:     0.75rem;
    --txt-m:     1.00rem;
    --txt-l:     1.20rem;
    --txt-xl:    1.40rem;
    --txt-xxl:   1.60rem;
  }
}