*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--clr-font);
  font-size: var(--txt-m);
  font-family: sans-serif;
  background: var(--clr-container);
}

h1 {
  font-size: var(--txt-xxl);
  pointer-events: none;
}

h2 {
  font-size: var(--txt-xl);
  pointer-events: none;
}

h3 {
  font-size: var(--txt-l);
  pointer-events: none;
}

h4 {
  font-size: var(--txt-m);
  pointer-events: none;
}

p {
  pointer-events: none;
}

a {
  color: var(--clr-font);
  text-decoration: none;
}

button {
  color: var(--clr-font);
  border: none;
  cursor: pointer;
}

input {
  border: none;
  outline: none;
  background: none;
  color: var(--clr-font);
}

textarea {
  border: none;
  outline: none;
  background: none;
  color: var(--clr-font);
}

::placeholder {
  color: var(--clr-border);
  opacity: 1;
}
