:root {
  --hvr-transition: transform 0.3s;
  --hvr-transform: scale(1.05);
}

@media (max-width: 480px) {
  :root {
    --hvr-transition: none;
    --hvr-transform: none;
  }
}

@keyframes slide-down {
  from {
    opacity: 1;
    line-height: 20px;
  }
  to {
    opacity: 0;
    line-height: 50px;
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    line-height: 50px;
  }
  to {
    opacity: 1;
    line-height: 20px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
